import * as React from "react"

import { Staff } from "api"
import { Action } from "../TabStaffUser"

const StaffRow: React.SFC<{
  staff: Staff
  showModalCallback: (action: Action, staff: Staff) => void
}> = props => {
  const { staff } = props

  const permisions = staff.permissions
  const handleActionModal = (action: Action) => () => {
    props.showModalCallback(action, props.staff)
  }

  return (
    <tr>
      <td>{staff.fullName}</td>
      <td>{staff.email}</td>
      <td className={staff.active ? "tick" : "cross"} />
      <td>{permisions.map((permission,idx) => {
        return <span className="role" key={idx}>{permission}</span>
      })}</td>
      <td>
        <button onClick={handleActionModal("Edit")} className="button_edit">
          Edit
        </button>
        <button
          onClick={handleActionModal("Password")}
          className="button_password"
        >
          Change password
        </button>
        <button onClick={handleActionModal("Delete")} className="button_delete">
          Delete
        </button>
      </td>
    </tr>
  )
}

export default StaffRow
