import * as React from "react"

import { EditStaffRequest, Staff } from "api"

import {
  FormFields,
  getFormValues,
  initField,
  onChange,
  onChangeCheckbox,
  ValidField,
} from "utils/form"

import * as validators from "utils/form/validators"

import * as Constants from '../../../utils/constants'

const permissionList = [
  Constants.PERMISSION_ISSUE,
  Constants.PERMISSION_REDEEM,
  Constants.PERMISSION_REDEEM_AND_ISSUE,
]


type StaffFields = FormFields<EditStaffRequest>

interface State {
  fields: StaffFields
}

interface ModalProps {
  staff: Staff
  handleClose: () => void
  performAction: (staff: EditStaffRequest) => void
}

class StaffEditModal extends React.Component<ModalProps, State> {
  public state: State = {
    fields: {
      id: new ValidField(this.props.staff.id),
      fullName: new ValidField(this.props.staff.fullName),
      permissions: this.props.staff.permissions !== undefined ? new ValidField(this.props.staff.permissions) : initField(),
      active: new ValidField(this.props.staff.active),
    },
  }

  // START FORM HANDLERS
  private formHandler = onChange(
    (f: StaffFields) =>
      this.setState(state => ({
        ...state,
        fields: f,
      })),
    () => this.state.fields,
  )
  private activeCheckboxFormHandler = onChangeCheckbox(
    (f: StaffFields) =>
      this.setState(state => ({
        ...state,
        fields: f,
      })),
    () => this.state.fields,
  )

  private onFullNameChange = this.formHandler(
    "fullName",
    validators.nonEmpty("Full name"),
    true,
  )
  // private onEmailChange = this.formHandler("email", validators.email())

  private onActiveChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.activeCheckboxFormHandler("active", validators.id())(ev)
  }

  private submitForm = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errsOrValues = getFormValues(this.state.fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        fields: errsOrValues.formFieldErrs,
      }))
      return
    }
    this.props.performAction(errsOrValues.values)
  }

  public onChangeRole = (ev : React.ChangeEvent<HTMLInputElement>) => {
    const role = ev.currentTarget.value
    let roles = this.state.fields.permissions instanceof ValidField ? this.state.fields.permissions.validValue : []
    if(roles.includes(role)){
      roles = roles.filter(x=>x !== role)
    }else{
      roles.push(role)
    }
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        permissions: new ValidField(roles)
      }
    })
  }

  public render() {
    return (
      <div className="modal display-block">
        <section className="modal-main">
          <div className="row margin_0_0_20">
            <label className="row">
              Full name{" "}
              {this.state.fields.fullName != null &&
              "error" in this.state.fields.fullName ? (
                <span className="error_message">
                  {this.state.fields.fullName.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              className="row"
              onChange={this.onFullNameChange}
              value={this.state.fields.fullName.getString()}
            />
          </div>
          <div className="row margin_0_0_20">
            <label className="row">
              Select Roles{" "}
              {this.state.fields.permissions != null &&
              "error" in this.state.fields.permissions ? (
                <span className="error_message">
                  {this.state.fields.permissions.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            {permissionList.map((role,idx)=>{
              return <label style={{ marginRight: "20px" }} key={idx}>
                <input
                  style={{
                    position: "relative",
                    top: "9px",
                    marginRight: '10px'
                  }}
                  type="checkbox"
                  value={role}
                  onChange={this.onChangeRole}
                  checked={this.state.fields.permissions instanceof ValidField ? this.state.fields.permissions.validValue.includes(role) : false}
                />
                {role}
              </label>
            })}
          </div>

          {/* <div className="row margin_0_0_20">
            <label className="row">
              Email{" "}
              {this.state.fields.email != null &&
              "error" in this.state.fields.email ? (
                <span className="error_message">
                  {this.state.fields.email.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="email"
              className="row"
              onChange={this.onEmailChange}
              value={this.state.fields.email.getString()}
            />
          </div> */}

          <div className="row margin_0_0_20">
            <label>
              <input
                style={{
                  position: "relative",
                  top: "9px",
                  marginRight: '10px'
                }}
                type="checkbox"
                onChange={this.onActiveChange}
                checked={this.state.fields.active.getString() === "true"}
              />
              Active
            </label>
          </div>
          <div className="row" style={{ textAlign: "center" }}>
            <button
              style={{ justifyContent: "center" }}
              onClick={this.submitForm}
            >
              Save
            </button>
          </div>
          <button
            className="modal-close-button"
            onClick={this.props.handleClose}
          >
            X
          </button>
        </section>
      </div>
    )
  }
}

export default StaffEditModal
