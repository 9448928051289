import * as React from "react"

const Loading: React.SFC = () => {
  return (
    <div className="loader show">
      <span className="loader_content">
        <span className="loader-inner" />
      </span>
    </div>
  )
}

export default Loading
