import * as api from "api"

import { SagaIterator } from "redux-saga"
import { put, takeEvery } from "redux-saga/effects"
import { actionCreatorFactory } from "typescript-fsa"
import { reducerWithInitialState } from "typescript-fsa-reducers"
import { done, failed, loading, notAsked, RemoteData } from "utils/remote-data"
import { sagaFromAPICall } from "utils/sagaFromAsyncActionCreator"

// Structure
export type Model = {
  lookupRequestStatus: RemoteData<api.LookupResponseBody>
  redeemRequestStatus: RemoteData<api.RedeemAndIssueResponseBody>
  issueRequestStatus: RemoteData<api.RedeemAndIssueResponseBody>
  toastMessage: boolean
}

export const initialState: Model = {
  lookupRequestStatus: notAsked(),
  redeemRequestStatus: notAsked(),
  issueRequestStatus: notAsked(),
  toastMessage: false,
}

// Acttions

const mkAction = actionCreatorFactory("Checkin")

export const lookup = mkAction.async<
  api.LookupRequest,
  api.LookupResponseBody,
  api.APIError[]
>("LOOKUP")
export const redeem = mkAction.async<
  api.RedeemAndIssueRequest,
  api.RedeemAndIssueResponseBody,
  api.APIError[]
>("REDEEM")
export const issue = mkAction.async<
  api.RedeemAndIssueRequest,
  api.RedeemAndIssueResponseBody,
  api.APIError[]
>("ISSUE")

export const toast = mkAction.async<{}, {}, api.APIError[]>(
  "CLEAR_TOAST_MESSAGE",
)

// Reducer

export const reducer = reducerWithInitialState<Model>(initialState)
  .case(lookup.started, (state, _) => ({
    ...state,
    lookupRequestStatus: loading(),
  }))
  .case(lookup.failed, (state, { error }) => ({
    ...state,
    lookupRequestStatus: failed(error),
    toastMessage: true,
  }))
  .case(lookup.done, (state, { result }) => ({
    ...state,
    lookupRequestStatus: done(result),
    toastMessage: true,
  }))
  .case(redeem.started, (state, _) => ({
    ...state,
    redeemRequestStatus: loading(),
  }))
  .case(redeem.failed, (state, { error }) => ({
    ...state,
    redeemRequestStatus: failed(error),
    toastMessage: true,
  }))
  .case(redeem.done, (state, { result }) => ({
    ...state,
    redeemRequestStatus: done(result),
    toastMessage: true,
  }))
  .case(issue.started, (state, _) => ({
    ...state,
    issueRequestStatus: loading(),
  }))
  .case(issue.failed, (state, { error }) => ({
    ...state,
    issueRequestStatus: failed(error),
    toastMessage: true,
  }))
  .case(issue.done, (state, { result }) => ({
    ...state,
    issueRequestStatus: done(result),
    toastMessage: true,
  }))
  .case(toast.done, (state, {}) => ({
    ...state,
    toastMessage: false,
  }))
  .build()

// Sagas

export function* rootSaga(): SagaIterator {
  yield takeEvery(lookup.started, lookupSaga)
  yield takeEvery(redeem.started, redeemSaga)
  yield takeEvery(issue.started, issueSaga)
}

const lookupSaga = sagaFromAPICall(lookup, api.lookup, function*(
  response,
  payload,
): SagaIterator {
  yield put(
    lookup.done({
      params: payload,
      result: response.data,
    }),
  )
})

const redeemSaga = sagaFromAPICall(redeem, api.redeem, function*(
  response,
  payload,
): SagaIterator {
  yield put(
    redeem.done({
      params: payload,
      result: response.data,
    }),
  )
})

const issueSaga = sagaFromAPICall(issue, api.issue, function*(
  response,
  payload,
): SagaIterator {
  yield put(
    issue.done({
      params: payload,
      result: response.data,
    }),
  )
})
