import * as React from "react"

import { DeleteStaffRequest, Staff } from "api"

const StaffDeleteModal: React.SFC<{
  staff: Staff
  handleClose: () => void
  performAction: (staff: DeleteStaffRequest) => void
}> = props => {
  const { staff } = props

  const handleActionModal = () => {
    if (props.staff.id) {
      const request: DeleteStaffRequest = {
        id: props.staff.id,
      }
      props.performAction(request)
    }
  }

  return (
    <div className="modal display-block">
      <section className="modal-main">
        <p>Are you sure you want to delete Staff User {staff.fullName}?</p>
        <div
          className="row"
          style={{
            textAlign: "center",
            marginTop: "25px",
          }}
        >
          <button
            style={{ justifyContent: "center" }}
            onClick={handleActionModal}
          >
            Confirm
          </button>
        </div>
        <button className="modal-close-button" onClick={props.handleClose}>
          X
        </button>
      </section>
    </div>
  )
}

export default StaffDeleteModal
