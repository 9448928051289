import * as React from "react"

import { Range } from "api"

const ColumnField: React.SFC<{
  title: string
  value: string | number
}> = props => {
  const { title, value } = props
  return (
    <div className="ind_col_50 float_left">
      <span className="title row">{title}</span>
      <span className="time row">{value}</span>
    </div>
  )
}

const RangeCard: React.SFC<{
  range: Range
  removeRange: (range: Range) => void
}> = props => {
  const { range } = props

  const handleRemove = () => {
    props.removeRange(range)
  }

  const toHHMMSS = (time: string) => {
    // var sec_num = parseInt(this, 10); // don't forget the second param
    let hours = parseInt(time.split(":")[0], 10)
    const amPm = hours < 12 ? " AM" : " PM"
    hours = hours % 12 || 12
    return hours + ":" + time.split(":")[1] + amPm
    // return time
  }

  return (
    <div className="label_card">
      <a
        style={{ cursor: "pointer" }}
        className="button_close"
        onClick={handleRemove}
      >
        X
      </a>
      <div className="row">
        <ColumnField title={"Start hour"} value={toHHMMSS(range.from)} />
        <ColumnField title={"End hour"} value={toHHMMSS(range.to)} />
      </div>
      <div className="row">
        <ColumnField title={"Reward"} value={range.reward} />
        <ColumnField title={"Cost"} value={range.entryCost} />
      </div>
    </div>
  )
}

export default RangeCard
