import * as React from "react"
import { Route, Switch } from "react-router-dom"

import { Model as AuthModel } from "store/auth"

import { connect } from "react-redux"
import { Redirect, RouteProps } from "react-router"
import { Store } from "store"

import Admin from "./admin/Admin"
import LoginPage from "./auth/Login"
import CheckIn from "./check-in/Checkin"

export const PrivateRouterImpl: React.StatelessComponent<
  RouteProps & AuthModel
> = ({ component, path, status, location }) => {
  if (status.type === "Logged in") {
    if (status.data.role === "admin") {
      return <Route component={Admin} path={path} />
    } else {
      return <Route component={CheckIn} path={path} />
    }
  } else {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />
  }
}

export const NotLoggedInRouterImpl: React.StatelessComponent<
  RouteProps & AuthModel
> = ({ component, path, status, location }) => {
  if (status.type === "Logged in") {
    return <Redirect to="/" />
  } else {
    return <Route component={component} path={path} />
  }
}

const App: React.SFC = () => (
  <div style={{ height: "100%" }}>
    <Switch>
      <PrivateRouter exact={true} path="/" />
      <NotLoggedInRouter path="/login" component={LoginPage} />
    </Switch>
  </div>
)

const PrivateRouter = connect(
  (store: Store) => store.auth,
  {},
)(PrivateRouterImpl)

const NotLoggedInRouter = connect(
  (store: Store) => store.auth,
  {},
)(NotLoggedInRouterImpl)

export default App
