import { Field, InvalidField as Fail, ValidField as Valid } from "utils/form"
import * as zxcvbnLib from "zxcvbn"

export type Validator<IN, OUT = IN> = (
  fieldName: string,
) => (input: IN) => Field<OUT>

export class Chain<IN, OUT = IN> {
  constructor(public fn: Validator<IN, OUT>) {}

  public chain = <NEWOUT>(fn: Validator<OUT, NEWOUT>) => {
    return new Chain((fieldName: string) => (input: IN) => {
      const field = this.fn(fieldName)(input)
      if ("validValue" in field) {
        return fn(fieldName)(field.validValue)
      }
      return field
    })
  }

  public call = this.fn
}

export const id = <T>() => (input: T) => new Valid(input)

export const nonEmpty: Validator<string> = fieldName => input =>
  input.length === 0
    ? new Fail(input, `Required field ${fieldName} is missing`)
    : new Valid(input)

const couldBeEmail = (str: string) => /.+@.+\..+/.test(str)
export const email = (_: string = "") => (input: string) =>
  couldBeEmail(input)
    ? new Valid(input)
    : new Fail(input, `Email address is invalid`)

export const length = (
  min: number,
  max: number,
): Validator<string> => fieldName => input =>
  input.length < min
    ? new Fail(
        input,
        `${fieldName} is too short. It should have at least ${min} characters, and no more than ${max}`,
      )
    : input.length > max
      ? new Fail(
          input,
          `${fieldName} is too long. It should have at least ${min} characters, and no more than ${max}`,
        )
      : new Valid(input)

export const positiveValue: Validator<number | string> = () => input =>
  input < 0 ? new Fail(input, `Can't be negative.`) : new Valid(input)

export const minLength = (
  min: number,
): Validator<string> => fieldName => input =>
  input.length < min
    ? new Fail(
        input,
        `${fieldName} is too short. It should be at least ${min} characters long`,
      )
    : new Valid(input)

export const maxLength = (
  max: number,
): Validator<string> => fieldName => input =>
  input.length > max
    ? new Fail(
        input,
        `${fieldName} is too long. It should be no more than ${max} characters long`,
      )
    : new Valid(input)

export const zxcvbn: Validator<string> = () => input => {
  const result = zxcvbnLib(input)
  return result.score < 1
    ? new Fail(
        input,
        `Password is insecure: ${result.feedback.warning ||
          "Choose a more complex one"}`,
      )
    : new Valid(input)
}

export const isNumber: Validator<string, number> = fieldName => input => {
  const parsed = parseInt(input, 10)
  return isNaN(parsed)
    ? new Fail(input, `${fieldName} must be a number`)
    : new Valid(parsed)
}

const couldBeUSChellphone = (str: string) =>
  /^(1)?[(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4}$/.test(str)
export const usCellphone = (_: string = "") => (input: string) =>
  couldBeUSChellphone(input)
    ? new Valid(input)
    : new Fail(input, `Phone must be only from US`)

export const over18: Validator<string> = fieldName => input => {
  const parts = input.split("-")
  // Please pay attention to the month (parts[1]); JavaScript counts months from 0: January - 0, February - 1, etc.
  const dateInput = new Date(
    parseInt(parts[0], 10) + 18,
    parseInt(parts[1], 10) - 1,
    parseInt(parts[2], 10),
  )
  return dateInput <= new Date()
    ? new Valid(input)
    : new Fail(input, `Age must be older than 18`)
}

export const validToHourMinutes: Validator<string> = fieldName => input => {
  return input.split(":")[1] !== "29" && input.split(":")[1] !== "59"
    ? new Fail(input, `To hour minutes must be 29 or 59`)
    : new Valid(input)
}

export const validFromHourMinutes: Validator<string> = fieldName => input => {
  return input.split(":")[1] !== "00" && input.split(":")[1] !== "30"
    ? new Fail(input, `To hour minutes must be 00 or 30`)
    : new Valid(input)
}
