import { Range } from "api"
import * as React from "react"

import {
  FormFields,
  getFormValues,
  initField,
  InvalidField,
  onChange,
  ValidField,
} from "utils/form"

import * as validators from "utils/form/validators"

import { InputHandler } from "utils/prelude"

type RangeFields = FormFields<Range>

interface State {
  fields: RangeFields
}

interface ModalProps {
  show: boolean
  weekDayRanges: Range[] | undefined
  addRange: (range: Range) => void
  handleClose: () => void
}

class Modal extends React.Component<ModalProps, State> {
  public state: State = {
    fields: {
      from: new ValidField("00:00"),
      to: new ValidField("00:29"),
      entryCost: initField(),
      reward: initField(),
    },
  }

  public validateRange = () => {
    let flag = true
    if (this.props.weekDayRanges) {
      this.props.weekDayRanges.forEach(range => {
        if (
          range.from <= this.state.fields.from.getString() &&
          range.to >= this.state.fields.from.getString()
        ) {
          flag = false
        } else {
          if (
            range.from <= this.state.fields.to.getString() &&
            range.to >= this.state.fields.to.getString()
          ) {
            flag = false
          }
        }
      })
    }
    return flag
  }

  // START FORM HANDLERS
  private formHandler = onChange(
    (f: RangeFields) =>
      this.setState(state => ({
        ...state,
        fields: f,
      })),
    () => this.state.fields,
  )

  private onFromChange: InputHandler = ev => {
    this.formHandler("from", validators.validFromHourMinutes("From"))(ev)
    this.updateToHour()
  }

  private onToChange: InputHandler = ev => {
    if (ev.currentTarget.value.includes(":30")) {
      ev.currentTarget.value = ev.currentTarget.value.replace(":30", ":29")
    }
    if (ev.currentTarget.value.includes(":00")) {
      ev.currentTarget.value = ev.currentTarget.value.replace(":00", ":59")
    }
    // this.formHandler("to", validators.validToHourMinutes("To"))(ev)
    // if ("validValue" in this.state.fields.to) {
    this.updateToHour(ev.currentTarget.value)
    // }
  }

  private updateToHour = (newToHour?: string) =>
    this.setState(state => {
      const val =
        newToHour !== undefined ? newToHour : state.fields.to.getString()
      const fromField = state.fields.from
      const updatedField = !("validValue" in fromField)
        ? new InvalidField(val, "Enter a valid From hour first")
        : fromField.validValue.split(":")[0] > val.split(":")[0]
          ? new InvalidField(val, "From hour must be lower than To hour")
          : fromField.validValue.split(":")[0] >= val.split(":")[0] &&
            fromField.validValue.split(":")[1] > val.split(":")[1]
            ? new InvalidField(val, "From hour must be lower than To hour")
            : validators.validToHourMinutes("From")(val)
      return {
        ...state,
        fields: {
          ...state.fields,
          to: updatedField,
        },
      }
    })

  private onRewardChange: InputHandler = ev => {
    this.formHandler(
      "reward",
      new validators.Chain(validators.isNumber)
        .chain(validators.positiveValue)
        .fn("Reward"),
    )(ev)
  }
  private onEntryCostChange: InputHandler = ev => {
    this.formHandler(
      "entryCost",
      new validators.Chain(validators.isNumber)
        .chain(validators.positiveValue)
        .fn("Entry cost"),
    )(ev)
  }

  public addRangeToRow = (range: Range) => {
    this.props.addRange(range)
  }

  public submitRange = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errsOrValues = getFormValues(this.state.fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        fields: errsOrValues.formFieldErrs,
      }))
      return
    } else {
      if (this.validateRange()) {
        this.addRangeToRow(errsOrValues.values)
      } else {
        this.setState({
          ...this.state,
          fields: {
            ...this.state.fields,
            to: new InvalidField(
              this.state.fields.to.getString(),
              "Range not allowed",
            ),
          },
        })
      }
    }
  }

  public render() {
    const showHideClassName = this.props.show
      ? "modal display-block"
      : "modal display-none"

    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          <div className="row margin_0_0_20">
            <label className="row">
              From{" "}
              {this.state.fields.from != null &&
              "error" in this.state.fields.from ? (
                <span className="error_message">
                  {this.state.fields.from.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="time"
              step="1800"
              className="row"
              onChange={this.onFromChange}
              value={this.state.fields.from.getString()}
            />
          </div>
          <div className="row margin_0_0_20">
            <label className="row">
              To{" "}
              {this.state.fields.to != null &&
              "error" in this.state.fields.to ? (
                <span className="error_message">
                  {this.state.fields.to.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="time"
              step="1800"
              className="row"
              onChange={this.onToChange}
              value={this.state.fields.to.getString()}
            />
          </div>
          <div className="row margin_0_0_20">
            <label className="row">
              Reward{" "}
              {this.state.fields.reward != null &&
              "error" in this.state.fields.reward ? (
                <span className="error_message">
                  {this.state.fields.reward.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="number"
              min={1}
              className="row"
              onChange={this.onRewardChange}
              value={this.state.fields.reward.getString()}
            />
          </div>
          <div className="row margin_0_0_20">
            <label className="row">
              Entry cost{" "}
              {this.state.fields.entryCost != null &&
              "error" in this.state.fields.entryCost ? (
                <span className="error_message">
                  {this.state.fields.entryCost.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="number"
              className="row"
              min={1}
              onChange={this.onEntryCostChange}
              value={this.state.fields.entryCost.getString()}
            />
          </div>
          <div className="row" style={{ textAlign: "center" }}>
            <button
              style={{ justifyContent: "center" }}
              onClick={this.submitRange}
            >
              Add range
            </button>
          </div>
          <button
            className="modal-close-button"
            onClick={this.props.handleClose}
          >
            X
          </button>
        </section>
      </div>
    )
  }
}

export default Modal
