import { APIError } from "api"

export type NotAsked = { status: "Not Asked" }

export type Loading = { status: "Loading" }

export type Failed = {
  status: "Failed"
  errors: APIError[]
}

export type Done<T> = { status: "Done"; data: T }

export type RemoteData<T> = NotAsked | Loading | Failed | Done<T>

export const notAsked = (): NotAsked => ({ status: "Not Asked" })

export const loading = (): Loading => ({ status: "Loading" })

export const failed = (errors: APIError[]): Failed => ({
  status: "Failed",
  errors,
})

export const done = <T>(data: T): Done<T> => ({
  data,
  status: "Done",
})
