import * as React from "react"
import { connect } from "react-redux"

import { ClientRule, LookupRequest, Patron } from "api"
import { Store } from "store"
import { logOut, Model as AuthModel } from "store/auth"
import { issue, lookup, Model as CheckinModel, redeem } from "store/check-in"
import * as Constants from '../../utils/constants'

import Loading from "components/Loading"
import {
  FormFields,
  getFormValues,
  initField,
  InvalidField,
  onChange,
  ValidField,
} from "utils/form"
import * as validators from "utils/form/validators"
import { InputHandler } from "utils/prelude"
import { notAsked } from "utils/remote-data"
import DateTimePage from "./DateTime"
import RowField from "./Row"
import Toast from "./Toast"

interface Props {
  auth: AuthModel
  checkIn: CheckinModel
  performLookup: typeof lookup.started
  performRedeem: typeof redeem.started
  performIssue: typeof issue.started
  performLogout: typeof logOut.started
}

type LookupFields = FormFields<{
  phone: string
  speedy: boolean
}>

type PointFields = FormFields<{
  points: number
}>

interface State {
  activeKeyboard: boolean
  lookup_fields: LookupFields
  point_fields: PointFields
  option: string
  newBalance: number | undefined
}

class CheckinPage extends React.Component<Props, State> {
  public state: State = {
    activeKeyboard: false,
    option: '',
    newBalance: undefined,
    lookup_fields: {
      phone: initField(),
      speedy: new ValidField(
        "speedy" in localStorage ? localStorage.getItem("speedy") : true,
      ),
    },
    point_fields: {
      points: initField(true)
    }
  }

  public componentDidUpdate(oldProps: Props) {
    const newProps = this.props
    if (
      oldProps.checkIn.issueRequestStatus.status !==
        newProps.checkIn.issueRequestStatus.status &&
      newProps.checkIn.issueRequestStatus.status === "Done"
    ) {
      const balance = Number(newProps.checkIn.issueRequestStatus.data.balance)
      this.setState({
        newBalance: balance
      })
    }
    if (
      oldProps.checkIn.redeemRequestStatus.status !==
        newProps.checkIn.redeemRequestStatus.status &&
      newProps.checkIn.redeemRequestStatus.status === "Done"
    ) {
      const balance = Number(newProps.checkIn.redeemRequestStatus.data.balance)
      this.setState({
        newBalance: balance
      })
    }
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    if (
      (event.target as any).nodeName &&
      (event.target as any).nodeName === "BODY"
    ) {
      const keyCode = event.keyCode
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105)
      ) {
        const phone = this.state.lookup_fields.phone.getString()
        const updatedPhone = validators.usCellphone()(phone + event.key)
        this.setState({
          ...this.state,
          lookup_fields: {
            ...this.state.lookup_fields,
            phone: updatedPhone,
          },
        })
        if (updatedPhone.getString().length === 10) {
          this.submitLookup()
        }
      }
    }
  }
  public componentWillMount() {
    const option = localStorage.getItem("option")
    if(option!== null){
      this.setState({
         option
      })
    }
    this.props.checkIn.lookupRequestStatus = notAsked()
    this.props.checkIn.issueRequestStatus = notAsked()
    this.props.checkIn.redeemRequestStatus = notAsked()

    window.scrollTo(0, 0)
    document.addEventListener("keydown", this.handleKeyDown, false)
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false)
  }

  public keyboardActive = () => {
    if(this.getOptionSelected() === Constants.PERMISSION_REDEEM_AND_ISSUE){
      this.setState({
        ...this.state,
        activeKeyboard: true,
      })
      document.body.classList.add("show")
      this.clearRequests()
    }
  }

  public keyboardInactive = (ev: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      activeKeyboard: false,
    })
    document.body.classList.remove("show")
    // this cast to any is not a good practice, but it works. Its not so good to access DOM elements in React.
    if (
      (ev.relatedTarget as any) &&
      (ev.relatedTarget as any).id === "lookup_button"
    ) {
      this.submitLookup()
    }
  }

  public clearButton = () => {
    const lookup_fields = this.state.lookup_fields
    lookup_fields.phone = initField()
    this.setState({
      ...this.state,
      lookup_fields,
      newBalance: undefined
    })
    this.clearRequests()
  }

  public clearRequests() {
    this.props.checkIn.lookupRequestStatus = notAsked()
    this.props.checkIn.issueRequestStatus = notAsked()
    this.props.checkIn.redeemRequestStatus = notAsked()
  }

  public handleSpeedyChange = () => {
    const lookup_fields = this.state.lookup_fields
    lookup_fields.speedy = new ValidField(
      !(this.state.lookup_fields.speedy as any).validValue,
    )
    this.setState(state => ({
      ...state,
      lookup_fields,
    }))

    // Update localhost setting value
    localStorage.setItem("speedy", lookup_fields.speedy.getString())
  }

  // START FORM HANDLERS
  private lookupFormHandler = onChange(
    (f: LookupFields) =>
      this.setState(state => ({
        ...state,
        lookup_fields: f,
      })),
    () => this.state.lookup_fields,
  )

  private onPhoneChange: InputHandler = ev => {
    this.clearRequests()
    if (ev.currentTarget.value.includes("tel:")) {
      ev.currentTarget.value = ev.currentTarget.value.replace("tel:", "")
    }
    // if (ev.currentTarget.value.length > 11) {
    //   return
    // }
    this.lookupFormHandler(
      "phone",
      validators.usCellphone(),
      // validators.nonEmpty("Phone"),
    )(ev)
  }

  private onPointChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const points = Number(ev.currentTarget.value.toString())
    const pointsField = points > 0 ? new ValidField(points) : new InvalidField(points,'Must be more than 0')
    this.setState({
      point_fields: {
        points: pointsField
      }
    })
  }

  private submitLookup = () => {
    // e.preventDefault()
      const errsOrValues = getFormValues(this.state.lookup_fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        lookup_fields: errsOrValues.formFieldErrs,
      }))
      return
    } else {
      const phoneNumber =
        errsOrValues.values.phone.length === 11
          ? errsOrValues.values.phone.slice(1, 11)
          : errsOrValues.values.phone
      const lookupRequest: LookupRequest = {
        phone: phoneNumber,
        speedy: errsOrValues.values.speedy,
        day: new Date()
          .toLocaleDateString("en-US", { weekday: "long" })
          .toLowerCase(),
        hour: new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        }),
        permission: this.getOptionSelected(),
      }
      this.clearRequests()
      this.props.performLookup(lookupRequest)
    }
  }

  private submitRedeem = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errsOrValues = getFormValues(this.state.lookup_fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        lookup_fields: errsOrValues.formFieldErrs,
      }))
      return
    } else {
      const errsOrValuesPoints = getFormValues(this.state.point_fields)
      if ("formFieldErrs" in errsOrValuesPoints) {
        this.setState(state => ({
          ...state,
          point_fields: errsOrValuesPoints.formFieldErrs,
        }))
        return
      }else{
        const payload = {
          phone: errsOrValues.values.phone,
          points: this.state.option !== Constants.PERMISSION_REDEEM_AND_ISSUE ? errsOrValuesPoints.values.points : 0,
          day: new Date()
            .toLocaleDateString("en-US", { weekday: "long" })
            .toLowerCase(),
          hour: new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }),
          permission: this.getOptionSelected()
        }
        this.clearButton()
        // this.props.checkIn.lookupRequestStatus = notAsked()
        this.props.performRedeem(payload)
      }
    }
  }

  private selectOption = (option: string) => () => {
    localStorage.setItem("option", option)
    this.setState({
      option,
      point_fields:{
        points: initField(option === Constants.PERMISSION_REDEEM_AND_ISSUE)
      }
    })
  }

  private submitIssue = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errsOrValues = getFormValues(this.state.lookup_fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        lookup_fields: errsOrValues.formFieldErrs,
      }))
      return
    } else {
      const errsOrValuesPoints = getFormValues(this.state.point_fields)
      if ("formFieldErrs" in errsOrValuesPoints) {
        this.setState(state => ({
          ...state,
          point_fields: errsOrValuesPoints.formFieldErrs,
        }))
        return
      }else{
        const payload = {
          phone: errsOrValues.values.phone,
          points: this.state.option !== Constants.PERMISSION_REDEEM_AND_ISSUE ? errsOrValuesPoints.values.points : 0,
          day: new Date()
            .toLocaleDateString("en-US", { weekday: "long" })
            .toLowerCase(),
          hour: new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }),
          permission: this.getOptionSelected()
        }
        this.clearButton()
        // this.props.checkIn.lookupRequestStatus = notAsked()
        this.props.performIssue(payload)
      }
    }
  }

  public getOptionSelected = () => {
    let option = this.state.option
    let permissions: string[] = []
    if(this.props.auth.status.type === "Logged in"){
      permissions = this.props.auth.status.data.permissions
      if(permissions === undefined){
        this.props.performLogout({})
      }else{
        if(permissions.length === 1){
          option = permissions[0]
        }else{
          if(permissions.length === 0){
            option = Constants.PERMISSION_REDEEM_AND_ISSUE
          }
        }
    }
    }
    return option
  }

  public render() {
    let patron: Patron | undefined
    let ruleClient: ClientRule | undefined
    let alreadyIssued = false
    let allowRedeem = false
    const { point_fields, newBalance } = this.state
    let reedemEnabledForPoints = true
    if (this.props.checkIn.lookupRequestStatus.status === "Done") {
      patron = this.props.checkIn.lookupRequestStatus.data.patron
      ruleClient = this.props.checkIn.lookupRequestStatus.data.ruleData
      alreadyIssued = this.props.checkIn.lookupRequestStatus.data.issued

      if (patron.lastCheckin && Date.parse(patron.lastCheckin)) {
        patron.lastCheckin = new Date(patron.lastCheckin).toLocaleString(
          "en-US",
        )
      }
      if (patron.dob && Date.parse(patron.dob)) {
        patron.dob = new Date(patron.dob).toLocaleDateString("en-US")
      }
    }
    const isRedeemAndIssue = this.getOptionSelected() === Constants.PERMISSION_REDEEM_AND_ISSUE
    if (isRedeemAndIssue && patron && ruleClient && patron.balance >= ruleClient.entryCost) {
      allowRedeem = true
    }

    // let patronBalance
    // if (this.props.checkIn.issueRequestStatus.status === "Done") {
    //   patronBalance = this.props.checkIn.issueRequestStatus.data.balance
    // }
    // if (this.props.checkIn.redeemRequestStatus.status === "Done") {
    //   patronBalance = this.props.checkIn.redeemRequestStatus.data.balance
    // }

    if (alreadyIssued) {
      setTimeout(this.clearButton, 500)
    }
    let pointsValidField = true
    // Clear cellphone if QR already used
    if (
      !alreadyIssued &&
      this.props.checkIn.lookupRequestStatus.status === "Done" &&
      this.props.checkIn.lookupRequestStatus.data.errorMessage ===
        "QR Code alredy used today."
    ) {
      setTimeout(this.clearButton, 500)
    }
    const option = this.getOptionSelected()
    let permissions: string[] = []
    if(this.props.auth.status.type === "Logged in"){
      permissions = this.props.auth.status.data.permissions
    }
    if(this.state.point_fields.points instanceof ValidField && patron!== undefined && this.state.point_fields.points.validValue > patron.balance && option === Constants.PERMISSION_REDEEM){
      point_fields.points = new InvalidField(this.state.point_fields.points.validValue,"The number can't be higher than balance")
      reedemEnabledForPoints = false
    }
    if(!(this.state.point_fields.points instanceof ValidField)){
      pointsValidField = false
    }
    return (
      <>
      {option === '' && 
        <div className="col">
        {permissions.map((access,idx)=>{
          return (
            <div  key={'role-'+access+idx} className="row padding_20_0_0">
              <button onClick={this.selectOption(access)} className="button gray row float_left">
                {access}  
              </button>
            </div>
          )
        })}
        </div>
      }
      {option !== '' && <div
        className={`row content ${
          this.state.activeKeyboard ? "active_keyboard" : ""
        }`}
      >
        {(this.props.checkIn.lookupRequestStatus.status === "Loading" ||
          this.props.checkIn.issueRequestStatus.status === "Loading" ||
          this.props.checkIn.redeemRequestStatus.status === "Loading") && (
          <Loading />
        )}
        <button className="button_crap" onClick={this.props.performLogout}>
          Logout
        </button>
        {/* <!--Col 1--> */}
        <div className="col col_1 ">
          {/* <!--Row--> */}
          <div className="row padding_20_0 content_information">
            <div className="ind_col_40 float_left content_path border_right">
              <span className="row user_text">
                {this.props.auth.status.type === "Logged in"
                  ? this.props.auth.status.data.fullName
                  : "Staff"}
              </span>
              {option === Constants.PERMISSION_REDEEM_AND_ISSUE && <><span className="speedy_label">Speedy Check-in:</span>
              <span className="row speedy_switch">
                <label style={{ float: "right" }} className="switch">
                  <input
                    type="checkbox"
                    checked={
                      this.state.lookup_fields.speedy.getString() === "true"
                    }
                    onChange={this.handleSpeedyChange}
                  />
                  <span className="slider round" />
                </label>
              </span></>}
            </div>
            <Toast />
          </div>
          {/* <!--Border--> */}
          <div className="row padding_20_0_0 border_bottom" />
          {/* <!--Row--> */}
          <div className="row padding_20_0_0">
            {/* <!--Row--> */}
            <div className="row margin_0_0_20">
              <label
                className={`row ${
                  this.state.lookup_fields.phone != null &&
                  "error" in this.state.lookup_fields.phone
                    ? "error"
                    : ""
                }`}
              >
                Phone
                {this.state.lookup_fields.phone != null &&
                "error" in this.state.lookup_fields.phone ? (
                  <span className="error_message">
                    {this.state.lookup_fields.phone.error}
                  </span>
                ) : (
                  <span className="error_message hidden" />
                )}
              </label>
              <input
                id="test_phone"
                onFocus={this.keyboardActive}
                onBlur={this.keyboardInactive}
                onChange={this.onPhoneChange}
                value={this.state.lookup_fields.phone.getString()}
                className={`row ${
                  this.state.lookup_fields.phone != null &&
                  "error" in this.state.lookup_fields.phone
                    ? "error"
                    : ""
                }`}
                type="string"
              />
            </div>
            {/* <!--Row--> */}
            <div className="row margin_0_0_20">
              <button
                id="lookup_button"
                onClick={this.submitLookup}
                disabled={
                  this.props.checkIn.lookupRequestStatus.status === "Loading"
                }
                className="button blue ind_col_65 float_left"
              >
                LOOKUP
              </button>
              <button
                onClick={this.clearButton}
                className="button blue ind_col_30 float_right white"
              >
                CLEAR
              </button>
            </div>
            {/* <!--Rows--> */}
            <RowField label="First" value={patron ? patron.first : ""} />
            <RowField label="Last" value={patron ? patron.last : ""} />
            <RowField label="DOB" value={patron ? patron.dob : ""} />
            <RowField
              label="Last Check-in"
              value={patron && patron.lastCheckin ? patron.lastCheckin : ""}
            />
            <RowField label="Email" value={patron ? patron.email : ""} />
          </div>
        </div>
        {option === '' && 
          <div className="col col_2">
          {permissions.map((access,idx)=>{
            return (
              <div  key={'role-'+access+idx} className="row padding_20_0_0">
                <button onClick={this.selectOption(access)} className="button gray row float_left">
                  {access}  
                </button>
              </div>
            )
          })}
          </div>
        }
        { (option === 'Issue' || option === Constants.PERMISSION_REDEEM) && 
          <div className="col col_2">
            <div className="row margin_0_0_20">
              <label
                className={`row ${
                  point_fields.points != null &&
                  "error" in point_fields.points
                    ? "error"
                    : ""
                }`}
              >
                Points
                {point_fields.points != null &&
                "error" in point_fields.points ? (
                  <span className="error_message">
                    {point_fields.points.error}
                  </span>
                ) : (
                  <span className="error_message hidden" />
                )}
              </label>
              <input
                id="points"
                onChange={this.onPointChange}
                value={point_fields.points.getString()}
                className={`row ${
                  point_fields.points != null &&
                  "error" in point_fields.points
                    ? "error"
                    : ""
                }`}
                type="number"
              />
            </div>
            <div className="row margin_0_0_20">
              <button 
                onClick={option === 'Issue' ? this.submitIssue : this.submitRedeem} 
                disabled={ option === 'Issue' ? (
                    alreadyIssued ||
                    !patron ||
                    !pointsValidField ||
                    // !ruleClient ||
                    this.props.checkIn.redeemRequestStatus.status === "Done" ||
                    this.props.checkIn.redeemRequestStatus.status === "Failed" ||
                    this.props.checkIn.issueRequestStatus.status === "Loading" ||
                    this.props.checkIn.issueRequestStatus.status === "Done" ||
                    (this.props.checkIn.lookupRequestStatus.status === "Done" &&
                      this.props.checkIn.lookupRequestStatus.data.errorMessage !==
                        undefined)
                ) : (
                  !reedemEnabledForPoints ||
                  alreadyIssued ||
                  !pointsValidField ||
                  // !allowRedeem 
                  !patron ||
                  // !ruleClient ||
                  this.props.checkIn.issueRequestStatus.status === "Done" ||
                  this.props.checkIn.issueRequestStatus.status === "Failed" ||
                  this.props.checkIn.redeemRequestStatus.status === "Loading" ||
                  this.props.checkIn.redeemRequestStatus.status === "Done" ||
                  (this.props.checkIn.lookupRequestStatus.status === "Done" &&
                    this.props.checkIn.lookupRequestStatus.data.errorMessage !==
                      undefined)
                )}
                className="button gray row float_left">
                    {option}  
              </button>
            </div>
            <RowField
              label="Balance"
              value={patron ? patron.balance.toString() : newBalance !== undefined ? newBalance.toString() : ''}
            />
          </div>
        }

        {/* <!--Col 2--> */}
        {option === Constants.PERMISSION_REDEEM_AND_ISSUE && <div className="col col_2">
          <RowField
            label="Entry Cost"
            value={ruleClient ? ruleClient.entryCost.toString() : ""}
          />
          <div className="row margin_0_0_20">
            <button
              onClick={this.submitRedeem}
              disabled={
                alreadyIssued ||
                !allowRedeem ||
                !patron ||
                !ruleClient ||
                this.props.checkIn.issueRequestStatus.status === "Done" ||
                this.props.checkIn.issueRequestStatus.status === "Failed" ||
                this.props.checkIn.redeemRequestStatus.status === "Loading" ||
                this.props.checkIn.redeemRequestStatus.status === "Done" ||
                (this.props.checkIn.lookupRequestStatus.status === "Done" &&
                  this.props.checkIn.lookupRequestStatus.data.errorMessage !==
                    undefined)
              }
              className="button yellow row float_left big redeem_button"
            >
              REDEEM
            </button>
          </div>
          <RowField
            label="Balance"
            value={patron ? patron.balance.toString() : ""}
          />
          <RowField
            label="Tier"
            value={patron && patron.tierName ? patron.tierName.toString() : ""}
          />
          <div className="row border_bottom" />
          <div className="row padding_20_0_0">
            <button disabled={true} className="button gray row float_left">
              CHECK-IN-HISTORY
            </button>
          </div>
          <div className="row margin_20_0_0">
            <button disabled={true} className="button pink row float_left">
              UNDO LAST CHECK-IN
            </button>
          </div>
        </div>}

        {/* <!--Col 3--> */}
        {option === Constants.PERMISSION_REDEEM_AND_ISSUE &&
        <div className="col col_3">
          <RowField
            label="Reward"
            value={ruleClient ? ruleClient.reward.toString() : ""}
          />
          <div className="row margin_0_0_20">
            <button
              onClick={this.submitIssue}
              disabled={
                alreadyIssued ||
                !patron ||
                !ruleClient ||
                this.props.checkIn.redeemRequestStatus.status === "Done" ||
                this.props.checkIn.redeemRequestStatus.status === "Failed" ||
                this.props.checkIn.issueRequestStatus.status === "Loading" ||
                this.props.checkIn.issueRequestStatus.status === "Done" ||
                (this.props.checkIn.lookupRequestStatus.status === "Done" &&
                  this.props.checkIn.lookupRequestStatus.data.errorMessage !==
                    undefined)
              }
              className="button green row float_left big"
            >
              ISSUE
            </button>
          </div>
          <div className="row border_bottom" />
          <div className="row padding_20_0_0">
            <RowField
              label="Entry Period"
              value={ruleClient ? ruleClient.from + " to " + ruleClient.to : ""}
            />
          </div>
          <div className="row border_bottom" />
          <DateTimePage />
        </div>}
      </div>}
    </>
    )
  }
}

export default connect(
  (store: Store) => ({ checkIn: store.checkIn, auth: store.auth, admin: store.admin }),
  {
    performLookup: lookup.started,
    performRedeem: redeem.started,
    performIssue: issue.started,
    performLogout: logOut.started,
  },
)(CheckinPage)
