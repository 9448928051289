import * as api from "api"

import { push } from "connected-react-router"
import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import { Action, actionCreatorFactory } from "typescript-fsa"
import { reducerWithInitialState } from "typescript-fsa-reducers"

// Structure

export type Status =
  | { type: "Not logged in" }
  | { type: "Logging in" }
  | {
      type: "Login failed"
    } & api.ErrorResponse
  | {
      type: "Logged in"
    } & api.LoginResponse
  | {
      type: "Loggin out"
    }

export type Model = {
  status: Status
}

export const initialState: Model = {
  status:
    localStorage.getItem("authStatus") !== null
      ? JSON.parse(localStorage.getItem("authStatus") as string)
      : { type: "Not logged in" },
}

// Acttions

const mkAction = actionCreatorFactory("Auth")

export const logIn = mkAction.async<
  api.LoginRequest,
  api.LoginResponseBody,
  api.APIError[]
>("LOG_IN")
export const logOut = mkAction.async<{}, {}, {}>("LOG_OUT")

// Reducer

export const reducer = reducerWithInitialState<Model>(initialState)
  .case(logIn.started, (state, _) => ({
    ...state,
    status: { type: "Logging in" },
  }))
  .case(logIn.failed, (state, { error }) => ({
    ...state,
    status: { type: "Login failed", errors: error },
  }))
  .case(logIn.done, (state, { result }) => ({
    ...state,
    status: { type: "Logged in", data: result },
  }))
  .case(logOut.started, (state, _) => ({
    ...state,
    status: { type: "Loggin out" },
  }))
  .case(logOut.done, (state, _) => ({
    ...state,
    status: { type: "Not logged in" },
  }))
  .build()

// Sagas

export function* rootSaga(): SagaIterator {
  yield takeEvery(logIn.started, logInSaga)
  yield takeEvery(logOut.started, logOutSaga)
}

function* logInSaga(action: Action<api.LoginRequest>): SagaIterator {
  try {
    const resp: api.Response<api.LoginResponseBody> = yield call(
      api.login,
      action.payload,
    )
    localStorage.removeItem('option')
    if ("errors" in resp) {
      yield put(
        logIn.failed({
          params: action.payload,
          error: resp.errors,
        }),
      )
      return
    }
    const newStatus: Status = {
      type: "Logged in",
      ...resp,
    }
    localStorage.setItem("authStatus", JSON.stringify(newStatus))
    yield put(
      logIn.done({
        params: action.payload,
        result: resp.data,
      }),
    )
    yield put(push("/"))
  } catch (e) {
    yield put(
      logIn.failed({
        params: action.payload,
        error: [
          {
            status: 0,
            title: "Request failed",
            message:
              "Something happened trying to make a network request, please try again later",
          },
        ],
      }),
    )
  }
}

export function* logOutSaga(): SagaIterator {
  try {
    localStorage.removeItem("authStatus")
    localStorage.removeItem("speedy")
    localStorage.removeItem("option")
    yield put(logOut.done({ params: {}, result: {} }))
    yield put(push("/login"))
  } catch (e) {
    // when connecting to BE we should catch ex here
  }
}
