import * as React from "react"
import { connect } from "react-redux"

import { APIError, LoginRequest } from "api"
import { Store } from "store"
import { logIn, Model as AuthModel } from "store/auth"

import Loading from "components/Loading"
import { FormFields, getFormValues, initField, onChange } from "utils/form"
import * as validators from "utils/form/validators"

interface Props extends AuthModel {
  performLogin: typeof logIn.started
}

type StateFields = FormFields<LoginRequest>

interface State {
  fields: StateFields
}

class LoginPage extends React.Component<Props, State> {
  public state: State = {
    fields: {
      email: initField(),
      password: initField(),
    },
  }

  public componentWillMount() {
    window.scrollTo(0, 0)
  }

  private formHandler = onChange(
    (f: StateFields) =>
      this.setState(state => ({
        ...state,
        fields: f,
      })),
    () => this.state.fields,
  )

  private onEmailChange = this.formHandler("email", validators.email())
  private onPasswordChange = this.formHandler(
    "password",
    validators.nonEmpty("Password"),
  )

  private submitForm = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    localStorage.removeItem('option')
    const errsOrValues = getFormValues(this.state.fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        fields: errsOrValues.formFieldErrs,
      }))
      return
    }
    this.props.performLogin(errsOrValues.values)
  }

  public render() {
    const { status } = this.props
    const errorMessage =
      status.type === "Login failed"
        ? status.errors.map((error: APIError, i: number) => (
            <p key={i} style={{ color: "red" }}>
              {error.key}: {error.message}
            </p>
          ))
        : null
    const fields = this.state.fields
    return (
      <div className="content_information_login">
        {this.props.status.type === "Logging in" && <Loading />}
        <h2>Sign In</h2>
        <form>
          <div className="row">
            <div className="row">
              <div className="row margin_0_0_40">
                <label
                  className={`row ${
                    this.state.fields.email != null &&
                    "error" in this.state.fields.email
                      ? "error"
                      : ""
                  }`}
                >
                  Email{" "}
                  {this.state.fields.email != null &&
                  "error" in this.state.fields.email ? (
                    <span className="error_message">
                      {this.state.fields.email.error}
                    </span>
                  ) : (
                    <span className="error_message hidden" />
                  )}
                </label>
                <input
                  className={`row ${
                    this.state.fields.email != null &&
                    "error" in this.state.fields.email
                      ? "error"
                      : ""
                  }`}
                  placeholder="Email"
                  type="email"
                  onChange={this.onEmailChange}
                  value={fields.email.getString()}
                />
              </div>

              <div className="row">
                <div className="wide column">
                  <label
                    className={`row ${
                      this.state.fields.password != null &&
                      "error" in this.state.fields.password
                        ? "error"
                        : ""
                    }`}
                  >
                    Password{" "}
                    {this.state.fields.password != null &&
                    "error" in this.state.fields.password ? (
                      <span className="error_message">
                        {this.state.fields.password.error}
                      </span>
                    ) : (
                      <span className="error_message hidden" />
                    )}
                  </label>
                  <input
                    className={`row ${
                      this.state.fields.password != null &&
                      "error" in this.state.fields.password
                        ? "error"
                        : ""
                    }`}
                    placeholder="Password"
                    type="password"
                    onChange={this.onPasswordChange}
                    value={fields.password.getString()}
                  />
                </div>
              </div>
              {errorMessage}
              <button
                className="button blue ind_col_65 float_left"
                style={{ margin: "50px 50px 50px 80px" }}
                onClick={this.submitForm}
                disabled={this.props.status.type === "Logging in"}
              >
                Sign in
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(
  (store: Store) => store.auth,
  { performLogin: logIn.started },
)(LoginPage)
