import * as React from "react"

import { APIError, CreateStaffRequest } from "api"

import {
  FormFields,
  getFormValues,
  initField,
  InvalidField,
  onChange,
  ValidField,
} from "utils/form"

import * as validators from "utils/form/validators"
import * as Constants from '../../../utils/constants'

const permissionList = [
  Constants.PERMISSION_ISSUE,
  Constants.PERMISSION_REDEEM,
  Constants.PERMISSION_REDEEM_AND_ISSUE,
]


import { InputHandler } from "utils/prelude"

type StaffFields = FormFields<{
  fullName: string
  email: string
  password: string
  confirmPassword: string
  permissions: string[]
}>

interface State {
  fields: StaffFields
}

interface ModalProps {
  handleClose: () => void
  performAction: (staff: CreateStaffRequest) => void
  actionErrors: undefined | APIError[]
}

class StaffCreateModal extends React.Component<ModalProps, State> {
  public state: State = {
    fields: {
      fullName: initField(),
      email: initField(),
      password: initField(),
      confirmPassword: initField(),
      permissions: initField(),
    },
  }

  // START FORM HANDLERS
  private formHandler = onChange(
    (f: StaffFields) =>
      this.setState(state => ({
        ...state,
        fields: f,
      })),
    () => this.state.fields,
  )

  private onFullNameChange = this.formHandler(
    "fullName",
    validators.nonEmpty("Full name"),
    true,
  )
  private onEmailChange = this.formHandler("email", validators.email())

  private onPasswordChange: InputHandler = ev => {
    this.formHandler(
      "password",
      new validators.Chain(validators.minLength(6))
        .chain(validators.zxcvbn)
        .fn("Password"),
    )(ev)
    this.updateConfirmPassword()
  }
  private updateConfirmPassword = (newConfirm?: string) =>
    this.setState(state => {
      const val =
        newConfirm !== undefined
          ? newConfirm
          : state.fields.confirmPassword.getString()
      const pwField = state.fields.password
      const updatedField = !("validValue" in pwField)
        ? new InvalidField(val, "Enter a valid password first")
        : pwField.validValue !== val
          ? new InvalidField(val, "Passwords do not match")
          : new ValidField(val)
      return {
        ...state,
        fields: {
          ...state.fields,
          confirmPassword: updatedField,
        },
      }
    })
  private onConfirmPasswordChange: InputHandler = ev =>
    this.updateConfirmPassword(ev.currentTarget.value)

  public onChangeRole = (ev : React.ChangeEvent<HTMLInputElement>) => {
    const role = ev.currentTarget.value
    let roles = this.state.fields.permissions instanceof ValidField ? this.state.fields.permissions.validValue : []
    if(roles.includes(role)){
      roles = roles.filter(x=>x !== role)
    }else{
      roles.push(role)
    }
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        permissions: new ValidField(roles)
      }
    })
  }

  private submitForm = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errsOrValues = getFormValues(this.state.fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        fields: errsOrValues.formFieldErrs,
      }))
      return
    }
    const request: CreateStaffRequest = {
      email: this.state.fields.email.getString(),
      fullName: this.state.fields.fullName.getString(),
      password: this.state.fields.password.getString(),
      permissions: this.state.fields.permissions instanceof ValidField ? this.state.fields.permissions.validValue : []
    }
    this.props.performAction(request)
  }

  public render() {
    const errorMessage =
      this.props.actionErrors !== undefined
        ? this.props.actionErrors.map((error: APIError, i: number) => (
            <p key={i} style={{ color: "red" }}>
              {error.message}
            </p>
          ))
        : null
    return (
      <div className="modal display-block">
        <section className="modal-main">
          <div className="row margin_0_0_20">
            <label className="row">
              Full name{" "}
              {this.state.fields.fullName != null &&
              "error" in this.state.fields.fullName ? (
                <span className="error_message">
                  {this.state.fields.fullName.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              className="row"
              onChange={this.onFullNameChange}
              value={this.state.fields.fullName.getString()}
            />
          </div>

          <div className="row margin_0_0_20">
            <label className="row">
              Email{" "}
              {this.state.fields.email != null &&
              "error" in this.state.fields.email ? (
                <span className="error_message">
                  {this.state.fields.email.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="email"
              className="row"
              onChange={this.onEmailChange}
              value={this.state.fields.email.getString()}
            />
          </div>

          <div className="row margin_0_0_20">
            <label className="row">
              Password{" "}
              {this.state.fields.password != null &&
              "error" in this.state.fields.password ? (
                <span className="error_message">
                  {this.state.fields.password.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="password"
              className="row"
              onChange={this.onPasswordChange}
              value={this.state.fields.password.getString()}
            />
          </div>

          <div className="row margin_0_0_20">
            <label className="row">
              Confirm Password{" "}
              {this.state.fields.password != null &&
              "error" in this.state.fields.confirmPassword ? (
                <span className="error_message">
                  {this.state.fields.confirmPassword.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="password"
              className="row"
              onChange={this.onConfirmPasswordChange}
              value={this.state.fields.confirmPassword.getString()}
            />
          </div>
          <div className="row margin_0_0_20">
            <label className="row">
              Select Roles{" "}
              {this.state.fields.permissions != null &&
              "error" in this.state.fields.permissions ? (
                <span className="error_message">
                  {this.state.fields.permissions.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            {permissionList.map((role,idx)=>{
              return <label  style={{ marginRight: "20px" }}  key={idx}>
                        <input
                          style={{
                            position: "relative",
                            top: "9px",
                            marginRight: '10px'
                          }}
                          type="checkbox"
                          value={role}
                          onChange={this.onChangeRole}
                          checked={this.state.fields.permissions instanceof ValidField ? this.state.fields.permissions.validValue.includes(role) : false}
                        />
                        {role}
                      </label>
            })}
          </div>
          {errorMessage}
          <div className="row" style={{ textAlign: "center" }}>
            <button
              style={{ justifyContent: "center" }}
              onClick={this.submitForm}
            >
              Create
            </button>
          </div>
          <button
            className="modal-close-button"
            onClick={this.props.handleClose}
          >
            X
          </button>
        </section>
      </div>
    )
  }
}

export default StaffCreateModal
