import * as React from "react"

import { Range } from "api"
import Modal from "../Modal"
import RangeCard from "./RangeCard"

interface State {
  showModal: boolean
  ranges: Range[]
}

interface Props {
  weekDay: string
  ranges: Range[]
  updateWeekDayCalendar: (weekDay: string, ranges: Range[]) => void
}

class DayRow extends React.Component<Props, State> {
  public state: State = {
    showModal: false,
    ranges: this.props.ranges,
  }

  private showModal = () => {
    this.setState({
      showModal: true,
    })
  }

  private hideModal = () => {
    this.setState({ showModal: false })
  }

  private removeRange = (range: Range) => {
    const ranges = this.state.ranges.filter(obj => {
      return obj.from !== range.from
    })
    this.setState({
      ...this.state,
      ranges,
    })
    this.props.updateWeekDayCalendar(this.props.weekDay, ranges)
  }

  public addRange = (range: Range) => {
    const ranges = this.state.ranges || []
    ranges.push(range)
    this.setState({
      ...this.state,
      ranges,
      showModal: false,
    })
    this.props.updateWeekDayCalendar(this.props.weekDay, ranges)
  }

  public render() {
    const { weekDay } = this.props
    return (
      <>
        <li className="row border_bottom">
          <ul className="row calendar_row">
            <li className="days">{weekDay}</li>
            <li className="tags">
              {/* <!--Button Plus--> */}
              <button className="button_plus" onClick={this.showModal}>
                +
              </button>

              {this.state.ranges &&
                this.state.ranges
                  .sort(
                    (a: Range, b: Range) =>
                      a.from > b.from ? 1 : b.from > a.from ? -1 : 0,
                  )
                  .map((range: Range, idx2: number) => (
                    <RangeCard
                      key={idx2}
                      range={range}
                      removeRange={this.removeRange}
                    />
                  ))}
            </li>
          </ul>
        </li>
        {this.state.showModal && (
          <Modal
            weekDayRanges={this.state.ranges}
            show={this.state.showModal}
            handleClose={this.hideModal}
            addRange={this.addRange}
          />
        )}
      </>
    )
  }
}

export default DayRow
