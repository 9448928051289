import * as React from "react"

import {
  ChangePasswordStaffRequest,
  CreateStaffRequest,
  DeleteStaffRequest,
  EditStaffRequest,
  Staff,
  StaffParams,
} from "api"
import Loading from "components/Loading"
import { connect } from "react-redux"
import { Store } from "store"
import {
  createStaff,
  deleteStaff,
  editStaff,
  getStaffList,
  Model as AdminModel,
  updatePasswordStaff,
} from "store/admin"
import { notAsked } from "utils/remote-data"
import StaffCreateModal from "./common/StaffCreateModal"
import StaffDeleteModal from "./common/StaffDeleteModal"
import StaffEditModal from "./common/StaffEditModal"
import StaffPasswordModal from "./common/StaffPasswordModal"
import StaffRow from "./common/StaffRow"

export type Action = "Create" | "Edit" | "Password" | "Delete"

interface Props {
  admin: AdminModel
  getStaffList: typeof getStaffList.started
  createStaff: typeof createStaff.started
  editStaff: typeof editStaff.started
  updatePasswordStaff: typeof updatePasswordStaff.started
  deleteStaff: typeof deleteStaff.started
}

interface State {
  showModal: Action | undefined
  actualStaff: Staff | undefined
  staffList: Staff[]
  moreStaffs: boolean
  lastStaffEmail: string | undefined
}

class TabStaffUser extends React.PureComponent<Props, State> {
  public state: State = {
    showModal: undefined,
    actualStaff: undefined,
    staffList: [],
    moreStaffs: true,
    lastStaffEmail: undefined,
  }

  public componentDidUpdate(oldProps: Props) {
    const newProps = this.props
    let moreStaffs = this.state.moreStaffs
    if (
      oldProps.admin.getStaffListRequestStatus.status !==
        newProps.admin.getStaffListRequestStatus.status &&
      newProps.admin.getStaffListRequestStatus.status === "Done"
    ) {
      let staffList = this.state.staffList
      if (staffList.length > 0 && staffList !== undefined) {
        newProps.admin.getStaffListRequestStatus.data.staffs.map(staff => {
          const alreadyExist = staffList.filter(obj => {
            return obj.email === staff.email
          })
          if (alreadyExist.length === 0) {
            staffList.push(staff)
          }
        })
      } else {
        staffList = newProps.admin.getStaffListRequestStatus.data.staffs
      }
      if (newProps.admin.getStaffListRequestStatus.data.staffs.length < 10) {
        moreStaffs = false
      }
      const lastStaffEmail = newProps.admin.getStaffListRequestStatus.data.staffs.slice(
        -1,
      )
        ? staffList.length > 0
          ? staffList.slice(-1)[0].email
          : undefined
        : undefined
      this.setState({
        ...this.state,
        staffList,
        moreStaffs,
        lastStaffEmail,
      })
    } else if (
      oldProps.admin.createStaffRequestStatus.status !==
        newProps.admin.createStaffRequestStatus.status &&
      newProps.admin.createStaffRequestStatus.status === "Done"
    ) {
      const staffList = this.state.staffList
      staffList.push(newProps.admin.createStaffRequestStatus.data.staff)
      this.setState({
        ...this.state,
        staffList,
        showModal: undefined,
        actualStaff: undefined,
      })
    } else if (
      oldProps.admin.deleteStaffRequestStatus.status !==
        newProps.admin.deleteStaffRequestStatus.status &&
      newProps.admin.deleteStaffRequestStatus.status === "Done" &&
      this.state.actualStaff !== undefined
    ) {
      const actualStaffId = this.state.actualStaff.id
      const staffList = this.state.staffList.filter(obj => {
        return obj.id !== actualStaffId
      })
      this.setState({
        ...this.state,
        staffList,
        showModal: undefined,
        actualStaff: undefined,
      })
    } else if (
      oldProps.admin.editStaffRequestStatus.status !==
        newProps.admin.editStaffRequestStatus.status &&
      newProps.admin.editStaffRequestStatus.status === "Done" &&
      this.state.actualStaff !== undefined
    ) {
      const actualStaffId = this.state.actualStaff.id
      const updatedStaff = newProps.admin.editStaffRequestStatus.data.staff
      const staffList = this.state.staffList.filter(obj => {
        if (obj.id === actualStaffId) {
          obj.active = updatedStaff.active
          obj.fullName = updatedStaff.fullName
          obj.permissions = updatedStaff.permissions
        }
        return obj
      })
      this.setState({
        ...this.state,
        staffList,
        showModal: undefined,
        actualStaff: undefined,
      })
    } else if (
      oldProps.admin.updatePasswordStaffRequestStatus.status !==
        newProps.admin.updatePasswordStaffRequestStatus.status &&
      newProps.admin.updatePasswordStaffRequestStatus.status === "Done"
    ) {
      this.setState({
        ...this.state,
        showModal: undefined,
        actualStaff: undefined,
      })
    }
  }

  public componentWillMount() {
    this.props.getStaffList({})
  }

  public componentWillUnmount() {
    this.props.admin.getStaffListRequestStatus = notAsked()
  }

  public showModal = (action: Action, staff?: Staff) => () => {
    this.setState({
      showModal: action,
      actualStaff: staff,
    })
  }

  public hideModal = () => {
    this.setState({ showModal: undefined })
  }

  public callbackShowModal = (action: Action, staff: Staff) => {
    this.showModal(action, staff)()
  }

  public performCreateStaff = (staff: CreateStaffRequest) => {
    this.props.createStaff(staff)
  }

  public performEditStaff = (staff: EditStaffRequest) => {
    this.props.editStaff(staff)
  }

  public performUpdatePasswordStaff = (staff: ChangePasswordStaffRequest) => {
    this.props.updatePasswordStaff(staff)
  }

  public performDeleteStaff = (staff: DeleteStaffRequest) => {
    this.props.deleteStaff(staff)
  }

  public getMore = () => {
    if (this.state.lastStaffEmail !== undefined) {
      const params: StaffParams = {
        lastEmail: this.state.lastStaffEmail,
      }
      this.props.getStaffList(params)
    }
  }

  public render() {
    const { staffList } = this.state
    return (
      <div className="row content_list">
        {this.state.showModal !== undefined &&
          this.state.showModal === "Create" && (
            <StaffCreateModal
              performAction={this.performCreateStaff}
              handleClose={this.hideModal}
              actionErrors={
                this.props.admin.createStaffRequestStatus.status === "Failed"
                  ? this.props.admin.createStaffRequestStatus.errors
                  : undefined
              }
            />
          )}
        {this.state.showModal !== undefined &&
          this.state.showModal === "Edit" &&
          this.state.actualStaff !== undefined && (
            <StaffEditModal
              performAction={this.performEditStaff}
              staff={this.state.actualStaff}
              handleClose={this.hideModal}
            />
          )}
        {this.state.showModal !== undefined &&
          this.state.showModal === "Password" &&
          this.state.actualStaff !== undefined && (
            <StaffPasswordModal
              performAction={this.performUpdatePasswordStaff}
              staff={this.state.actualStaff}
              handleClose={this.hideModal}
            />
          )}
        {this.state.showModal !== undefined &&
          this.state.showModal === "Delete" &&
          this.state.actualStaff !== undefined && (
            <StaffDeleteModal
              performAction={this.performDeleteStaff}
              staff={this.state.actualStaff}
              handleClose={this.hideModal}
            />
          )}
        {(this.props.admin.getStaffListRequestStatus.status === "Loading" ||
          this.props.admin.createStaffRequestStatus.status === "Loading" ||
          this.props.admin.editStaffRequestStatus.status === "Loading" ||
          this.props.admin.updatePasswordStaffRequestStatus.status ===
            "Loading" ||
          this.props.admin.deleteStaffRequestStatus.status === "Loading") && (
          <Loading />
        )}
        <button onClick={this.showModal("Create")} className="button_create">
          Create
        </button>
        {this.props.admin.getStaffListRequestStatus.status === "Done" ? (
          staffList.length > 0 ? (
            <>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Active</th>
                    <th>Permissions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {staffList.map((staff, idx) => {
                    return (
                      <StaffRow
                        key={idx}
                        staff={staff}
                        showModalCallback={this.callbackShowModal}
                      />
                    )
                  })}
                </tbody>
              </table>
              {this.state.moreStaffs && (
                <div className="get_more_staff_button">
                  <a onClick={this.getMore}>Get more</a>
                </div>
              )}
            </>
          ) : (
            <p>No staffs</p>
          )
        ) : this.props.admin.getStaffListRequestStatus.status === "Failed" ? (
          <p>Error</p>
        ) : null}
      </div>
    )
  }
}

export default connect(
  (store: Store) => ({ admin: store.admin }),
  {
    getStaffList: getStaffList.started,
    createStaff: createStaff.started,
    editStaff: editStaff.started,
    updatePasswordStaff: updatePasswordStaff.started,
    deleteStaff: deleteStaff.started,
  },
)(TabStaffUser)
