import { APIError, DataResponse, Response as APIResponse } from "api"
import { SagaIterator } from "redux-saga"
import { call, cancelled, put } from "redux-saga/effects"
import { Action, AsyncActionCreators } from "typescript-fsa"

import { push } from "connected-react-router"
import { logOut } from "store/auth"

export class SagaAPIError extends Error {
  constructor(public errors: APIError[]) {
    super(errors[0] ? errors[0].title : "Unknown error")
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export const sagaFromAPICall = <Req, Resp>(
  actionCreator: AsyncActionCreators<Req, Resp, APIError[]>,
  apiCall: (payload: Req) => Promise<APIResponse<Resp>>,
  postCallWorker?: (response: DataResponse<Resp>, payload: Req) => SagaIterator,
) => {
  function* boundAsyncActionSaga(
    params: Action<Req>,
    ...args: any[]
  ): SagaIterator {
    // Error helpers
    const fail = (error: APIError[]) =>
      put(
        actionCreator.failed({
          params: params.payload,
          error,
        }),
      )
    const msgFail = (title: string, detail?: string) =>
      fail([
        {
          status: 0,
          title,
          message: detail !== undefined ? detail : title,
        },
      ])
    const networkError = () =>
      msgFail(
        "Network error",
        "There was an error contacting the server, please check your Internet connection and try again",
      )
    const authenticationFailed = () =>
      msgFail("Auhtentication failed", "Session expired. Please login again")
    const unknownError = () =>
      msgFail(
        "Unknown error",
        "An unknown error ocurred. If this issue persists, contact support",
      )

    try {
      const result = yield call(apiCall, params.payload)

      if ("errors" in result) {
        if (result.statusCode === 401) {
          localStorage.removeItem("authStatus")
          yield put(logOut.done({ params: {}, result: {} }))
          yield put(push("/"))
          yield authenticationFailed()
        } else {
          yield fail(result.errors)
        }
        return
      } else if (!("data" in result)) {
        yield unknownError()
        return
      }

      if (postCallWorker !== undefined) {
        yield call(postCallWorker, result, params.payload)
      }
      yield put(
        actionCreator.done({ params: params.payload, result: result.data }),
      )
      return result
    } catch (e) {
      if (e instanceof DOMException) {
        if (e.name === "AbortError") {
          yield msgFail("Request was cancelled")
        } else if (e.name === "NetworkError") {
          yield networkError()
        }
      } else if (e instanceof TypeError) {
        // console.log(e)
        if (e.name.startsWith("NetworkError")) {
          yield networkError()
        }
      } else if (e instanceof SagaAPIError) {
        yield put(
          actionCreator.failed({ params: params.payload, error: e.errors }),
        )
      }
      yield unknownError()
      // tslint:disable-next-line:no-console
      console.error(e)
    } finally {
      if (yield cancelled()) {
        yield msgFail("Request was cancelled")
      }
    }
  }

  const funcForName = postCallWorker ? postCallWorker : apiCall
  const capName =
    funcForName.name.charAt(0).toUpperCase() + funcForName.name.substring(1)

  return setFunctionName(
    boundAsyncActionSaga,
    `bound${capName}(${actionCreator.type})`,
  )
}

/**
 * Set function name.
 *
 * Note that this won't have effect on built-in Chrome stack traces, although
 * useful for stack traces generated by `redux-saga`.
 */
// tslint:disable-next-line:ban-types
function setFunctionName<F extends Function>(func: F, name: string): F {
  try {
    Object.defineProperty(func, "name", {
      value: name,
      configurable: true,
    })
  } catch (e) {
    // ignore
  }
  return func
}
