import * as React from "react"

import { ClientSettings } from "api"
import { connect } from "react-redux"
import { Store } from "store"
import { getClient, Model as AdminModel } from "store/admin"
import { logOut, Model as AuthModel } from "store/auth"
import Loading from "../Loading"
import TabSettings from "./TabSettings"
import TabStaffUser from "./TabStaffUser"

export type AdminTabs = "Settings" | "StaffUsers"

interface State {
  actualTabIndex: AdminTabs
}

interface Props {
  auth: AuthModel
  admin: AdminModel
  getClient: typeof getClient.started
  performLogout: typeof logOut.started
}

class AdminPage extends React.Component<Props, State> {
  public state: State = {
    actualTabIndex: "Settings",
  }

  public componentWillMount() {
    this.props.admin.getClientRequestStatus.status = "Not Asked"
    if (
      this.props.auth.status.type === "Logged in" &&
      this.props.auth.status.data.role === "admin" &&
      this.props.auth.status.data.client_id
    ) {
      this.props.getClient({ clientId: this.props.auth.status.data.client_id })
    } else {
      // send to login
      this.props.performLogout({})
    }

    document.body.classList.remove("show")
    window.scrollTo(0, 0)
  }

  private changeTab = () => {
    if (this.state.actualTabIndex === "Settings") {
      this.setState({
        ...this.state,
        actualTabIndex: "StaffUsers",
      })
    } else {
      this.setState({
        ...this.state,
        actualTabIndex: "Settings",
      })
    }
  }

  public render() {
    const { status } = this.props.admin.getClientRequestStatus
    if (status === "Loading") {
      return <Loading />
    } else if (this.props.admin.getClientRequestStatus.status === "Done") {
      const { name, phone_list } = this.props.admin.getClientRequestStatus.data

      let settings = this.props.admin.getClientRequestStatus.data.settings
      if (
        this.props.admin.updateClientSettingsRequestStatus.status === "Done"
      ) {
        settings = this.props.admin.updateClientSettingsRequestStatus.data
          .settings
      }

      const { actualTabIndex } = this.state
      return (
        <div className="row content_admin">
          <button className="button_crap" onClick={this.props.performLogout}>
            Logout
          </button>
          <div className="row margin_0_0_40">
            <h3 className="row margin_0_0_20">{name}</h3>
            <p style={{ fontWeight: "bold" }}>Phone list:</p>
            {phone_list.map((ph: number, idx: number) => {
              return (
                <span key={idx} className="row text">
                  {ph}
                </span>
              )
            })}
          </div>

          {/* <!--Buttons--> */}
          <ul className="row border_bottom">
            <li className="ind_col_45 float_left margin_0_0_20">
              <button
                className={
                  "row" + (actualTabIndex === "Settings" ? " active" : "")
                }
                disabled={actualTabIndex === "Settings"}
                onClick={this.changeTab}
              >
                Settings
              </button>
            </li>
            <li className="ind_col_45 float_right margin_0_0_20">
              <button
                className={
                  "row" + (actualTabIndex === "StaffUsers" ? " active" : "")
                }
                disabled={actualTabIndex === "StaffUsers"}
                onClick={this.changeTab}
              >
                Staff List
              </button>
            </li>
          </ul>
          <TabContainer
            actualTabIndex={this.state.actualTabIndex}
            settings={settings}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

const TabContainer = (props: {
  actualTabIndex: AdminTabs
  settings: ClientSettings
}) => {
  switch (props.actualTabIndex) {
    case "Settings":
      return <TabSettings settings={props.settings} />
    case "StaffUsers":
      return <TabStaffUser />
    default:
      return null
  }
}

export default connect(
  (store: Store) => ({ admin: store.admin, auth: store.auth }),
  {
    getClient: getClient.started,
    performLogout: logOut.started,
  },
)(AdminPage)
