import { combineReducers } from "redux"
import { all, fork } from "redux-saga/effects"
import * as admin from "store/admin"
import * as auth from "store/auth"
import * as checkIn from "store/check-in"

export type Store = {
  auth: auth.Model
  checkIn: checkIn.Model
  admin: admin.Model
}

export const rootReducer = combineReducers<Store>({
  auth: auth.reducer,
  checkIn: checkIn.reducer,
  admin: admin.reducer,
})

export function* rootSaga() {
  yield all([fork(auth.rootSaga), fork(checkIn.rootSaga), fork(admin.rootSaga)])
}
