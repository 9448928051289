import { APIError } from "api"
import * as React from "react"
import { connect } from "react-redux"
import { Store } from "store"
import { Model as CheckinModel, toast } from "store/check-in"

interface Props {
  checkIn: CheckinModel
  toast: typeof toast.done
}

const Toast: React.StatelessComponent<Props> = props => {
  let patron
  let ruleClient
  let allowRedeem = false
  let alreadyIssued = false
  if (props.checkIn.lookupRequestStatus.status === "Done") {
    patron = props.checkIn.lookupRequestStatus.data.patron
    ruleClient = props.checkIn.lookupRequestStatus.data.ruleData
    alreadyIssued = props.checkIn.lookupRequestStatus.data.issued
  }
  if (patron && ruleClient && patron.balance >= ruleClient.entryCost) {
    allowRedeem = true
  }
  const errorMessage = props.checkIn.toastMessage ? (
    props.checkIn.lookupRequestStatus.status === "Failed" ? (
      props.checkIn.lookupRequestStatus.errors.map(
        (error: APIError, i: number) => {
          return (
            <p key={i} className="error">
              {error.message.length > 0 ? error.message : ""}
            </p>
          )
        },
      )
    ) : props.checkIn.issueRequestStatus.status === "Failed" ? (
      props.checkIn.issueRequestStatus.errors.map(
        (error: APIError, i: number) => (
          <p key={i} className="error">
            {error.message}
          </p>
        ),
      )
    ) : props.checkIn.redeemRequestStatus.status === "Failed" ? (
      props.checkIn.redeemRequestStatus.errors.map(
        (error: APIError, i: number) => (
          <p key={i} className="error">
            {error.message}
          </p>
        ),
      )
    ) : props.checkIn.lookupRequestStatus.status === "Done" &&
    props.checkIn.lookupRequestStatus.data.errorMessage ? (
      <p className="error">
        {props.checkIn.lookupRequestStatus.data.errorMessage}
      </p>
    ) : null
  ) : null

  const successMessage = props.checkIn.toastMessage ? (
    props.checkIn.lookupRequestStatus.status === "Done" && alreadyIssued ? (
      <p className="success">"Issued correct"</p>
    ) : props.checkIn.lookupRequestStatus.status === "Done" &&
    props.checkIn.redeemRequestStatus.status !== "Done" &&
    !alreadyIssued &&
    allowRedeem &&
    props.checkIn.lookupRequestStatus.data.errorMessage === undefined ? (
      <p className="success">"Redeem is possible!"</p>
    ) : props.checkIn.redeemRequestStatus.status === "Done" ? (
      <p className="success">"Redeem correct"</p>
    ) : props.checkIn.issueRequestStatus.status === "Done" ? (
      <p className="success">"Issued correct"</p>
    ) : null
  ) : null

  if (props.checkIn.toastMessage) {
    setTimeout(props.toast, 4000)
  }
  return (
    <div className="ind_col_60 float_left border_right padding_0_10 center_align toast_text">
      <span className="row">
        {errorMessage}
        {successMessage}
      </span>
    </div>
  )
}

export default connect(
  (store: Store) => ({ checkIn: store.checkIn }),
  {
    toast: toast.done,
  },
)(Toast)
