let domain = window.location.hostname

domain =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
    ? "127.0.0.1:3000"
    : domain

const apiUrl = domain.includes("127.0.0.1")
  ? `http://localhost:8080`
  : // domain.includes("appspot")
  //   ?
  domain.includes("stage")
  ? `https://oc-stage-access.appspot.com`
  : `https://oc-prod-access.appspot.com`
// : domain.includes("stage")
//   ? `https://us-central1-oc-stage-access.cloudfunctions.net/api`
//   : `https://us-central1-oc-prod-access.cloudfunctions.net/api`

export interface DataResponse<T> {
  data: T
}

export type APIErrorSource = { pointer: string } | { parameter: string }

export interface APIError {
  status: number
  title: string
  key?: string
  message: string
  source?: APIErrorSource
}

export interface ErrorResponse {
  errors: APIError[]
}

export type Response<T> = DataResponse<T> | ErrorResponse

async function http<RespBody>(
  url: string,
  opts: RequestInit & {
    body?: undefined
    payload?: any
    headers?: Headers
  },
  queryParams?: any,
): Promise<Response<RespBody>> {
  const { headers = new Headers(), payload, ...restOpts } = opts
  headers.append("Content-Type", "application/json")

  const queryUrl = new URL(apiUrl + url)
  if (queryParams) {
    Object.keys(queryParams).forEach(key =>
      queryUrl.searchParams.append(key, queryParams[key]),
    )
  }
  const resp = await fetch(queryUrl.toString(), {
    body: payload !== undefined ? JSON.stringify(payload) : undefined,
    headers,
    credentials: "include",
    ...restOpts,
  })
  return resp.json()
}

// LOGIN //
export type Role = "admin" | "staff"

export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponseBody {
  role: Role
  client_id?: string
  fullName?: string
  permissions: string[]
}
export type LoginResponse = DataResponse<LoginResponseBody>

export const login = (payload: LoginRequest) =>
  http<LoginResponseBody>("/auth/login", { method: "post", payload })

// LOOKUP //
export interface LookupRequest {
  phone: string
  speedy: boolean
  day: string
  hour: string
  permission: string
}

export interface Patron {
  id: string
  first: string
  last: string
  dob: string
  lastCheckin?: string
  email: string
  tierName?: string
  balance: number
}
export interface ClientRule {
  entryCost: number
  reward: number
  from: string
  to: string
}
export interface LookupResponseBody {
  patron: Patron
  ruleData: ClientRule
  issued: boolean
  errorMessage?: string
}
export type LookupResponse = DataResponse<LookupResponseBody>

export const lookup = (payload: LookupRequest) =>
  http<LookupResponseBody>(
    "/staff/lookUp/" + payload.phone,
    {},
    { speedy: payload.speedy, day: payload.day, hour: payload.hour, permission: payload.permission },
  )

// REDEEM & ISSUE //
export interface RedeemAndIssueRequest {
  phone: string
  day: string
  hour: string
  points: number,
  permission: string
}

export interface RedeemAndIssueResponseBody {
  balance: string
}
export type RedeemResponse = DataResponse<RedeemAndIssueResponseBody>

export const redeem = (payload: RedeemAndIssueRequest) =>
  http<RedeemAndIssueResponseBody>(
    "/staff/redeem",
    { method: "post", payload },
    { day: payload.day, hour: payload.hour, permission: payload.permission },
  )

export type IssueResponse = DataResponse<RedeemAndIssueResponseBody>

export const issue = (payload: RedeemAndIssueRequest) =>
  http<RedeemAndIssueResponseBody>(
    "/staff/issue",
    { method: "post", payload },
    { day: payload.day, hour: payload.hour, permission: payload.permission },
  )

// ADMIN SETTINGS //

export interface ClientRequest {
  clientId: string
}

export interface Range {
  entryCost: number
  reward: number
  from: string
  to: string
}

export interface WeekDay {
  monday?: {
    ranges: Range[]
  }
  tuesday?: {
    ranges: Range[]
  }
  wednesday?: {
    ranges: Range[]
  }
  thursday?: {
    ranges: Range[]
  }
  friday?: {
    ranges: Range[]
  }
  saturday?: {
    ranges: Range[]
  }
  sunday?: {
    ranges: Range[]
  }
}

export interface ClientSettings {
  minutesToReuseQr: number
  minAge: number
  rewardIntervals: WeekDay
}
export interface ClientResponseBody {
  name: string
  phone_list: number[]
  settings: ClientSettings
}

export type ClientResponse = DataResponse<ClientResponseBody>

export const getClient = (payload: ClientRequest) =>
  http<ClientResponseBody>("/admin/client/" + payload.clientId, {})
  
export interface ClientUpdateRequest {
  clientId: string
  settings: ClientSettings
}

export interface ClientUpdateResponseBody {
  id: string
  settings: ClientSettings
}

export type ClientUpdateResponse = DataResponse<ClientUpdateResponseBody>

export const updateClient = (payload: ClientUpdateRequest) =>
  http<ClientUpdateResponseBody>("/admin/client/" + payload.clientId, {
    method: "put",
    payload: { settings: payload.settings },
  })

export interface StaffParams {
  order?: string
  pageSize?: number
  lastEmail?: string
}

export interface Staff {
  id?: string
  email: string
  active?: boolean
  createdAt?: string
  fullName: string
  permissions: string[]
}

export interface StaffListResponseBody {
  staffs: Staff[]
}

export type StaffListResponse = DataResponse<StaffListResponseBody>

export const getStaffList = (payload: StaffParams) =>
  http<StaffListResponseBody>("/admin/staff/", {}, payload)

export interface CreateStaffRequest {
  email: string
  fullName: string
  password: string
  permissions: string[]
}

export interface StaffResponseBody {
  staff: Staff
}

export type CreateStaffResponse = DataResponse<StaffResponseBody>

export const createStaff = (payload: CreateStaffRequest) =>
  http<StaffResponseBody>("/admin/staff/", { method: "post", payload })

export interface EditStaffRequest {
  id: string
  permissions: string[]
  active: boolean
  fullName: string
}

export type EditStaffResponse = DataResponse<StaffResponseBody>

export const editStaff = (payload: EditStaffRequest) =>
  http<StaffResponseBody>("/admin/staff/" + payload.id, {
    method: "put",
    payload,
  })

export interface ChangePasswordStaffRequest {
  id: string
  newPassword: string
}

export const updatePasswordStaff = (payload: ChangePasswordStaffRequest) =>
  http<string>("/admin/staff/" + payload.id + "/changePassword", {
    method: "put",
    payload,
  })

export interface DeleteStaffRequest {
  id: string
}

export const deleteStaff = (payload: DeleteStaffRequest) =>
  http<string>("/admin/staff/" + payload.id, { method: "delete" })
