export class FieldInit {
  constructor(public isOptional?: boolean) {}
  public getString() {
    return ""
  }
}
export class InvalidField<T> {
  constructor(public input: T, public error: string) {}
  public getString() {
    return String(this.input)
  }
}
export class ValidField<T> {
  constructor(public validValue: T) {}
  public getString() {
    return String(this.validValue)
  }
}

export type Field<T> = FieldInit | InvalidField<T> | ValidField<T>

export type FormFields<Container> = {
  [Prop in keyof Container]: Field<Container[Prop]>
}

export function getFormValues<T>(
  form: FormFields<T>,
): { formFieldErrs: FormFields<T> } | { values: T } {
  const values: any = {}
  const formFieldErrs: any = {}
  let hasError = false
  for (const [key, value] of Object.entries(form)) {
    if ("validValue" in value) {
      values[key] = (value as any).validValue
    } else if ("error" in value) {
      hasError = true
    } else if (!("isOptional" in value && (value as any).isOptional)) {
      formFieldErrs[key] = new InvalidField("", "Field is required")
      hasError = true
      continue
    }
    formFieldErrs[key] = value
  }
  return hasError ? { formFieldErrs } : { values }
}

export const initField = (isOptional?: boolean) => new FieldInit(isOptional)

export const onChange = <
  FieldsState extends object,
  FieldKey extends keyof FieldsState
>(
  containerSetter: (f: FieldsState) => void,
  currentContainer: () => FieldsState,
) => (
  fieldKey: FieldKey,
  validator: (input: string) => FieldsState[FieldKey],
  skipTrim?: boolean,
) => (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  containerSetter({
    // Casted as any due to compiler bug:
    // https://github.com/Microsoft/TypeScript/issues/10727
    ...(currentContainer() as any),
    [fieldKey]: skipTrim
      ? validator(ev.currentTarget.value)
      : validator(ev.currentTarget.value.trim()),
  })
}

export const onChangeCheckbox = <
  FieldsState extends object,
  FieldKey extends keyof FieldsState
>(
  containerSetter: (f: FieldsState) => void,
  currentContainer: () => FieldsState,
) => (
  fieldKey: FieldKey,
  validator: (input: boolean) => FieldsState[FieldKey],
  skipTrim?: boolean,
) => (ev: React.ChangeEvent<HTMLInputElement>) => {
  if (ev.currentTarget.checked !== undefined) {
    containerSetter({
      // Casted as any due to compiler bug:
      // https://github.com/Microsoft/TypeScript/issues/10727
      ...(currentContainer() as any),
      [fieldKey]: validator(ev.currentTarget.checked),
    })
  }
}
