import * as React from "react"

interface RowColumnFieldProps {
  label: string
  value?: string
  type?: string
}

const RowField: React.SFC<RowColumnFieldProps> = props => {
  const { label, value, type } = props
  return (
    <div className="row margin_0_0_20">
      <label className="row">{label}</label>
      <input
        type={type ? type : ""}
        className="row"
        disabled={true}
        value={value ? value : ""}
      />
    </div>
  )
}

export default RowField
