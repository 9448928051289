import * as api from "api"

import { SagaIterator } from "redux-saga"
import { put, takeEvery } from "redux-saga/effects"
import { actionCreatorFactory } from "typescript-fsa"
import { reducerWithInitialState } from "typescript-fsa-reducers"
import { done, failed, loading, notAsked, RemoteData } from "utils/remote-data"
import { sagaFromAPICall } from "utils/sagaFromAsyncActionCreator"

// Structure
export type Model = {
  getClientRequestStatus: RemoteData<api.ClientResponseBody>
  updateClientSettingsRequestStatus: RemoteData<api.ClientUpdateResponseBody>
  getStaffListRequestStatus: RemoteData<api.StaffListResponseBody>
  createStaffRequestStatus: RemoteData<api.StaffResponseBody>
  editStaffRequestStatus: RemoteData<api.StaffResponseBody>
  updatePasswordStaffRequestStatus: RemoteData<string>
  deleteStaffRequestStatus: RemoteData<string>
}

export const initialState: Model = {
  getClientRequestStatus: notAsked(),
  updateClientSettingsRequestStatus: notAsked(),
  getStaffListRequestStatus: notAsked(),
  createStaffRequestStatus: notAsked(),
  editStaffRequestStatus: notAsked(),
  updatePasswordStaffRequestStatus: notAsked(),
  deleteStaffRequestStatus: notAsked(),
}

// Acttions

const mkAction = actionCreatorFactory("Checkin")

export const getClient = mkAction.async<
  api.ClientRequest,
  api.ClientResponseBody,
  api.APIError[]
>("GET_CLIENT")
export const updateClient = mkAction.async<
  api.ClientUpdateRequest,
  api.ClientUpdateResponseBody,
  api.APIError[]
>("UPDATE_CLIENT_SETTINGS")
export const getStaffList = mkAction.async<
  api.StaffParams,
  api.StaffListResponseBody,
  api.APIError[]
>("GET_STAFF_LIST")
export const createStaff = mkAction.async<
  api.CreateStaffRequest,
  api.StaffResponseBody,
  api.APIError[]
>("CREATE_STAFF")
export const editStaff = mkAction.async<
  api.EditStaffRequest,
  api.StaffResponseBody,
  api.APIError[]
>("EDIT_STAFF")
export const updatePasswordStaff = mkAction.async<
  api.ChangePasswordStaffRequest,
  string,
  api.APIError[]
>("UPDATE_STAFF_PASSWORD")
export const deleteStaff = mkAction.async<
  api.DeleteStaffRequest,
  string,
  api.APIError[]
>("DELETE_STAFF")

// Reducer

export const reducer = reducerWithInitialState<Model>(initialState)
  .case(getClient.started, (state, _) => ({
    ...state,
    getClientRequestStatus: loading(),
  }))
  .case(getClient.failed, (state, { error }) => ({
    ...state,
    getClientRequestStatus: failed(error),
  }))
  .case(getClient.done, (state, { result }) => ({
    ...state,
    getClientRequestStatus: done(result),
  }))
  .case(updateClient.started, (state, _) => ({
    ...state,
    updateClientSettingsRequestStatus: loading(),
  }))
  .case(updateClient.failed, (state, { error }) => ({
    ...state,
    updateClientSettingsRequestStatus: failed(error),
  }))
  .case(updateClient.done, (state, { result }) => ({
    ...state,
    updateClientSettingsRequestStatus: done(result),
  }))
  .case(getStaffList.started, (state, _) => ({
    ...state,
    getStaffListRequestStatus: loading(),
  }))
  .case(getStaffList.failed, (state, { error }) => ({
    ...state,
    getStaffListRequestStatus: failed(error),
  }))
  .case(getStaffList.done, (state, { result }) => ({
    ...state,
    getStaffListRequestStatus: done(result),
  }))
  .case(createStaff.started, (state, _) => ({
    ...state,
    createStaffRequestStatus: loading(),
  }))
  .case(createStaff.failed, (state, { error }) => ({
    ...state,
    createStaffRequestStatus: failed(error),
  }))
  .case(createStaff.done, (state, { result }) => ({
    ...state,
    createStaffRequestStatus: done(result),
  }))
  .case(editStaff.started, (state, _) => ({
    ...state,
    editStaffRequestStatus: loading(),
  }))
  .case(editStaff.failed, (state, { error }) => ({
    ...state,
    editStaffRequestStatus: failed(error),
  }))
  .case(editStaff.done, (state, { result }) => ({
    ...state,
    editStaffRequestStatus: done(result),
  }))
  .case(updatePasswordStaff.started, (state, _) => ({
    ...state,
    updatePasswordStaffRequestStatus: loading(),
  }))
  .case(updatePasswordStaff.failed, (state, { error }) => ({
    ...state,
    updatePasswordStaffRequestStatus: failed(error),
  }))
  .case(updatePasswordStaff.done, (state, { result }) => ({
    ...state,
    updatePasswordStaffRequestStatus: done(result),
  }))
  .case(deleteStaff.started, (state, _) => ({
    ...state,
    deleteStaffRequestStatus: loading(),
  }))
  .case(deleteStaff.failed, (state, { error }) => ({
    ...state,
    deleteStaffRequestStatus: failed(error),
  }))
  .case(deleteStaff.done, (state, { result }) => ({
    ...state,
    deleteStaffRequestStatus: done(result),
  }))
  .build()

// Sagas

export function* rootSaga(): SagaIterator {
  yield takeEvery(getClient.started, getClientSaga)
  yield takeEvery(updateClient.started, updateClientSaga)
  yield takeEvery(getStaffList.started, getStaffListSaga)
  yield takeEvery(createStaff.started, createStaffSaga)
  yield takeEvery(editStaff.started, editStaffSaga)
  yield takeEvery(updatePasswordStaff.started, updatePasswordStaffSaga)
  yield takeEvery(deleteStaff.started, deleteStaffSaga)
}

const getClientSaga = sagaFromAPICall(getClient, api.getClient, function*(
  response,
  payload,
): SagaIterator {
  // yield put(
  //   getClient.done({
  //     params: payload,
  //     result: response.data,
  //   }),
  // )
})

const updateClientSaga = sagaFromAPICall(
  updateClient,
  api.updateClient,
  function*(response, payload): SagaIterator {
    // yield put(
    //   getClient.done({
    //     params: payload,
    //     result: response.data,
    //   }),
    // )
  },
)

const getStaffListSaga = sagaFromAPICall(
  getStaffList,
  api.getStaffList,
  function*(response, payload): SagaIterator {
    yield put(
      getStaffList.done({
        params: payload,
        result: response.data,
      }),
    )
  },
)

const createStaffSaga = sagaFromAPICall(createStaff, api.createStaff, function*(
  response,
  payload,
): SagaIterator {
  yield put(
    createStaff.done({
      params: payload,
      result: response.data,
    }),
  )
})

const editStaffSaga = sagaFromAPICall(editStaff, api.editStaff, function*(
  response,
  payload,
): SagaIterator {
  yield put(
    editStaff.done({
      params: payload,
      result: response.data,
    }),
  )
})

const updatePasswordStaffSaga = sagaFromAPICall(
  updatePasswordStaff,
  api.updatePasswordStaff,
  function*(response, payload): SagaIterator {
    yield put(
      updatePasswordStaff.done({
        params: payload,
        result: response.data,
      }),
    )
  },
)

const deleteStaffSaga = sagaFromAPICall(deleteStaff, api.deleteStaff, function*(
  response,
  payload,
): SagaIterator {
  yield put(
    deleteStaff.done({
      params: payload,
      result: response.data,
    }),
  )
})
