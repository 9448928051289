import * as React from "react"
import * as ReactDOM from "react-dom"

import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from "connected-react-router"
import { createBrowserHistory } from "history"
import { Provider } from "react-redux"
import { applyMiddleware, compose, createStore } from "redux"

import sagaMiddleware from "redux-saga"
import { rootReducer, rootSaga } from "store"
import App from "./components/App"

const history = createBrowserHistory()

const sagaMW = sagaMiddleware()

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(applyMiddleware(sagaMW, routerMiddleware(history))),
)

sagaMW.run(rootSaga)

class RootComponent extends React.Component {
  public render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    )
  }
}

ReactDOM.render(<RootComponent />, document.getElementById("app-container"))
