import * as React from "react"

interface State {
  curTime: string
  curDate: string
  interval: any
}

export default class DateTimePage extends React.Component<{}, State> {
  public state: State = {
    curTime: "",
    curDate: "",
    interval: undefined,
  }

  public componentDidMount() {
    this.state.interval = setInterval(() => {
      this.setState({
        curTime: new Date().toLocaleTimeString("en-US"),
        curDate: new Date().toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      })
    }, 1000)
  }

  public componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  // public shouldComponentUpdate() {
  //   return false
  // }

  public render() {
    return (
      <>
        <div className="row padding_20_0_0">
          <label className="row">Time</label>
          <input
            // type="time"
            className="row"
            disabled={true}
            value={this.state.curTime}
          />
        </div>
        <div className="row padding_20_0_0">
          <label className="row">Date</label>
          <input
            // type="date"
            className="row"
            disabled={true}
            value={this.state.curDate}
          />
        </div>
      </>
    )
  }
}
