import * as React from "react"

import { ClientSettings, ClientUpdateRequest, Range } from "api"
import Loading from "components/Loading"
import { connect } from "react-redux"
import { Store } from "store"
import { Model as AdminModel, updateClient } from "store/admin"
import { Model as AuthModel } from "store/auth"
import { FormFields, getFormValues, onChange, ValidField } from "utils/form"
import * as validators from "utils/form/validators"
import { InputHandler } from "utils/prelude"
import DayRow from "./common/DayRow"

type ClientSettingsFields = FormFields<ClientSettings>

interface State {
  fields: ClientSettingsFields
}

interface Props {
  auth: AuthModel
  admin: AdminModel
  performUpdateClient: typeof updateClient.started
  settings: ClientSettings
}

class TabSettings extends React.Component<Props, State> {
  public state: State = {
    fields: {
      minutesToReuseQr: new ValidField(this.props.settings.minutesToReuseQr),
      minAge: new ValidField(this.props.settings.minAge),
      rewardIntervals: new ValidField(this.props.settings.rewardIntervals),
    },
  }

  // START FORM HANDLERS
  public formHandler = onChange(
    (f: ClientSettingsFields) =>
      this.setState(state => ({
        ...state,
        fields: f,
      })),
    () => this.state.fields,
  )

  public onMinutesReuseQRChange: InputHandler = ev => {
    this.formHandler(
      "minutesToReuseQr",
      new validators.Chain(validators.isNumber)
        .chain(validators.positiveValue)
        .fn("Minutes to reuse QR"),
    )(ev)
  }
  public onMinAgeChange: InputHandler = ev => {
    this.formHandler(
      "minAge",
      new validators.Chain(validators.isNumber)
        .chain(validators.positiveValue)
        .fn("Minimum age"),
    )(ev)
  }

  public getClientId = () => {
    return this.props.auth.status.type === "Logged in" &&
      this.props.auth.status.data.role === "admin" &&
      this.props.auth.status.data.client_id ? this.props.auth.status.data.client_id : ''
  }

  public submitChanges = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errsOrValues = getFormValues(this.state.fields)
    if ("formFieldErrs" in errsOrValues) {
      this.setState(state => ({
        ...state,
        fields: errsOrValues.formFieldErrs,
      }))
      return
    } else {
      const clientId = this.getClientId()
      const clientUpdateRequest: ClientUpdateRequest = {
        clientId,
        settings: errsOrValues.values,
      }
      this.props.performUpdateClient(clientUpdateRequest)
    }
  }

  public updateWeekDayCalendar = (weekDay: string, ranges: Range[]) => {
    const updatedDay = (this.props.settings.rewardIntervals as any)[weekDay]
    updatedDay.ranges = ranges

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        rewardIntervals: new ValidField(this.props.settings.rewardIntervals),
      },
    })
  }

  public render() {
    const { minutesToReuseQr, minAge } = this.state.fields
    const { rewardIntervals } = this.props.settings
    const WeekDaysValue = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    }
    return (
      <div className="row content_calendar">
        {this.props.admin.updateClientSettingsRequestStatus.status ===
          "Loading" && <Loading />}
        <div className="row">
          <div className="row margin_0_0_20" style={{ width: "36%" }}>
            <label className="row">
              Minutes to reuse QR
              {minutesToReuseQr != null && "error" in minutesToReuseQr ? (
                <span className="error_message admin">
                  {minutesToReuseQr.error}
                </span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="number"
              min={1}
              className="row"
              onChange={this.onMinutesReuseQRChange}
              value={minutesToReuseQr.getString()}
            />
          </div>
          <div
            className="row margin_0_0_20"
            style={{ width: "36%", marginLeft: "30px" }}
          >
            <label className="row">
              Minimum age required
              {minAge != null && "error" in minAge ? (
                <span className="error_message admin">{minAge.error}</span>
              ) : (
                <span className="error_message hidden" />
              )}
            </label>
            <input
              type="number"
              min={1}
              className="row"
              onChange={this.onMinAgeChange}
              value={minAge.getString()}
            />
          </div>
          <button
            className="button_apply_changes"
            disabled={
              this.props.admin.updateClientSettingsRequestStatus.status ===
              "Loading"
            }
            onClick={this.submitChanges}
          >
            Apply changes
          </button>
        </div>
        <ul className="row">
          {Object.keys(rewardIntervals)
            .sort((a: string, b: string) => {
              const valueA: number = (WeekDaysValue as any)[a]
              const valueB: number = (WeekDaysValue as any)[b]
              return valueA > valueB ? 1 : valueB > valueA ? -1 : 0
            })
            .map((weekDay, idx) => (
              <DayRow
                key={idx}
                weekDay={weekDay}
                ranges={(rewardIntervals as any)[weekDay].ranges}
                updateWeekDayCalendar={this.updateWeekDayCalendar}
              />
            ))}
        </ul>
      </div>
    )
  }
}

export default connect(
  (store: Store) => ({ admin: store.admin,  auth: store.auth }),
  {
    performUpdateClient: updateClient.started,
  },
)(TabSettings)
